import React from 'react'
import about from 'assets/images/about.png'
import { Link } from 'react-router-dom'

function Sobre() {
  return (
    <section className='flex w-full items-center justify-center'>
      <div className='my-10 grid w-11/12 grid-cols-1 items-center gap-8 md:my-20 md:w-10/12 md:grid-cols-2'>
        <picture>
          <img src={about} alt='Quem Somos' loading='lazy' className='w-full rounded-xl' />
        </picture>

        <article className='flex flex-col gap-2'>
          <header className='text-3xl font-semibold uppercase md:text-5xl'>Quem Somos</header>

          <p className='text-justify text-xl'>
            Somos uma empresa especializada, representando mais de 8.500 módulos instalados em diversas regiões do
            Brasil, em projetos variados, incluindo residências, comércios, propriedades rurais, indústrias e órgãos
            públicos. Nossa confiabilidade resulta em uma média de 445.000 kWh por mês, atendendo mais de 49 cidades.
          </p>

          <p className='text-justify text-xl'>
            Na ICTUS SOLUÇÕES EM ENERGIA, valorizamos cada cliente individualmente, comprometendo-nos a estabelecer
            conexões transparentes e honestas.
          </p>

          <Link
            to='/sobre'
            className='w-full rounded-xl bg-[#006f45] p-4 text-center text-2xl uppercase text-white hover:opacity-75 md:w-2/3 md:text-3xl'
          >
            Conheça nossa história
          </Link>
        </article>
      </div>
    </section>
  )
}

export default Sobre
